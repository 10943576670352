:root {
    --primary: #17616B;
    --lightPrimary: #199aac;
    --bgcolor: #182A4B;
    --bgcolor-1: #153769;
    --bglight: #F7FAFB;
    --bglight-1: #F8F9FD;
    --bggray: #bdbec5;
    --bglightGray: #d2d3dd;
    --textcolor: #333333;
    --red: #EB255B;
    --boldOrange: #EC4C3B;
    --lightOrange: #F6A644;
    --orange: #F46443;
    --cyan: #34E496;
    --lightCyan: #35E1C4;
    --violet: #656ADA;
    --boldViolet: #7C56DD;
}

/* GLOBAL STYLES */

html {
    font-size: 15px;
}

body {
    overflow-x: hidden;
    color: var(--textcolor);
}

.kanit-font {
    font-family: 'Kanit', sans-serif;
}

a {
    color: inherit;
    transition: 0.3s ease;
    text-decoration: none;
}

a:hover {
    color: var(--lightPrimary);
}

a.no-color-change:hover {
    color: inherit;
}

img.cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

img.h-cover {
    height: 100%;
    width: auto;
    object-fit: cover;
}

.btn_custom {
    padding: .6rem 1.5rem;
    border-radius: 5px;
    border: none;
    display: inline-block;
    background: var(--primary);
    font-weight: 600;
    color: var(--bglight);
    font-size: 1rem;
    transition: .2s ease;
}

.btn_custom:hover {
    filter: brightness(1.15);
}

button,
input,
textarea,
select {
    outline: none !important;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
    box-shadow: none !important;
}

.text-primary {
    color: var(--lightPrimary) !important;
}

.bg_none {
    background: none !important;
}

.opacity-5 {
    opacity: .75;
}

.bold {
    font-weight: bold;
}

/* --------------------------------------------------------------------- */
/* THE LOGIN PAGE STYLES */
/* --------------------------------------------------------------------- */

.login_wrapper {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    background: var(--bgcolor);
    padding: 100px 0;
}

.login_wrapper::before {
    content: '';
    position: absolute;
    left: -10%;
    top: -10%;
    width: 120%;
    height: 400px;
    background: linear-gradient(to right, var(--primary), var(--bgcolor-1));
    transform: skewY(-2deg);
}

.form_content {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0%);
    border-radius: 10px;
    background: var(--bglight);
    padding: 40px 35px;
    width: clamp(250px, 500px, 750px);
    min-height: 400px;
    margin-bottom: 50px;
}

.logo_lg {
    height: 180px;
}

.copyrights {
    text-align: center;
    padding: 25px 15px;
    color: var(--bglight);
}

@media screen and (max-width: 768px) {
    .logo_lg {
        height: 150px;
    }

    .form_content {
        padding: 30px 25px;
        width: 450px;
    }
}

@media screen and (max-width: 480px) {
    .logo_lg {
        height: 100px;
    }

    .form_content {
        padding: 30px 15px;
        width: 85%;
    }
}


/* --------------------------------------------------------------------- */
/* THE DASHBOARD PAGE PAGE STYLES */
/* --------------------------------------------------------------------- */

.dashboard_wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    justify-content: flex-end;
}

ul.dropdown-menu.show {
    width: 220px;
    top: 15px !important;
}

/* --------------------------------------------------------------------- */
/* The sidebar component styles */
/* --------------------------------------------------------------------- */

.sidebar {
    width: 280px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 18px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    overflow-y: auto;
    background: var(--bglight);
    transition: .2s ease;
}

.side_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.shrink {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    transition: .2s ease;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.shrink:hover {
    background: var(--bglight-1);
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.shrink i {
    font-size: 1.5rem;
}

.shrink.light {
    color: var(--bglight);
}

.shrink.light:hover {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: transparent;
}

.logo_md {
    height: 80px;
}

.side_link {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    border-radius: 8px;
    background: transparent;
    color: rgba(0, 0, 0, 0.4);
    transition: .2s ease;
    margin-bottom: 12px;
}

.side_link:hover {
    background: var(--bglight-1);
}

.side_link.active {
    background: var(--bglight-1);
    color: var(--bgcolor-1);
    font-weight: 500;
}

.side_link i {
    font-size: 1.5rem;
}

.sidebar .shrink {
    display: none;
}

@media screen and (max-width: 768px) {
    .sidebar {
        left: -100%;
    }

    .sidebar .shrink {
        display: flex;
    }

    .sidebar.active {
        left: 0;
    }
}


/* --------------------------------------------------------------------- */
/* The main content component styles */
/* --------------------------------------------------------------------- */

.main_content {
    min-height: 100vh;
    width: calc(100% - 280px);
    background-color: var(--bglight-1);
}

@media screen and (max-width: 768px) {
    .main_content {
        width: 100%;
    }
}


/* --------------------------------------------------------------------- */
/* The topbar component styles */
/* --------------------------------------------------------------------- */

.topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 25px;
    background: var(--lightPrimary);
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.topbar::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -201px;
    width: 100%;
    height: 200px;
    background: var(--lightPrimary);
}

.topbar.dashboard_bg::after {
    height: 500px;
    bottom: -501px;
}


.profile_pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bggray);
}

.topbar .toggler .shrink {
    display: none;
}

@media screen and (max-width: 768px) {
    .topbar .toggler .shrink {
        display: flex;
    }
}


/* --------------------------------------------------------------------- */
/* The content component styles */
/* --------------------------------------------------------------------- */

.content {
    position: relative;
    z-index: 5;
    padding: 0 25px;
}

.content_header {
    padding: 30px 0;
}

.stat_card {
    background: var(--bglight);
    padding: 20px;
    border-radius: 8px;
    min-height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.stat_icon {
    color: var(--bglight);
    background: linear-gradient(45deg, var(--red), var(--boldOrange));
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.progression {
    transition: .3s ease;
    height: 4px;
    border-radius: 4px;
    background: var(--bglightGray);
    overflow: hidden;
}

.progression .progression_status {
    background: linear-gradient(45deg, var(--red), var(--orange));
    height: 100%;
    border-radius: 4px;
}

.stat_numbers {
    padding-right: 15px;
    width: calc(100% - 65px);
}

.stat_card.orange .stat_icon,
.stat_card.orange .progression_status {
    background: linear-gradient(45deg, var(--orange), var(--lightOrange));
}

.stat_card.cyan .stat_icon,
.stat_card.cyan .progression_status {
    background: linear-gradient(45deg, var(--cyan), var(--lightCyan));
}

.stat_card.violet .stat_icon,
.stat_card.violet .progression_status {
    background: linear-gradient(45deg, var(--violet), var(--boldViolet));
}

.widgets .card {
    min-height: 700px;
}

.message_box {
    padding: 15px 20px;
    transition: .2s ease;
    background: transparent;
    max-width: 650px;
    /* Définir la largeur maximale de la div parente */

    overflow: hidden;
    /* Masquer le contenu qui dépasse les limites de la div */
    text-overflow: ellipsis;
    /* Ajouter des points de suspension à la fin du texte tronqué */
    word-wrap: break-word;
    /* Forcer le texte à se diviser en plusieurs lignes si nécessaire */
}

.message_box:hover {
    background: var(--bglight);
    cursor: pointer;
}

.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(138, 122, 122);
    opacity: 0.6;
    /* Firefox */
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    align-items: center;
}

.pagination li {
    display: inline-block;
    margin: 0 0.2rem;
}

.pagination a,
.pagination span {
    padding: 0.3rem 0.75rem;
    color: #5e72e4;  
    border: 1px solid #5e72e4;
    border-radius: 0.25rem;
    font-size: .8rem;
}

.pagination a:hover,
.pagination span:hover {
    background-color: #5e72e4;
    color: #fff;
}

.pagination .active a,
.pagination .active span {
    background-color: #5e72e4;
    color: #fff;
}

.file-preview {
    position: relative;
}

.file-preview img,
.file-preview video,
.file-preview audio {
    display: block;
    max-width: 100%;
    max-height: 200px;
}

.file-preview Button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    font-size: 12px;
}

/* the custom nav */
.custom-nav {
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    color: var(--violet);
}

.custom-nav.active {
    color: white !important;
    background: var(--violet) !important;
}

.custom-nav-tabs {
    border-color: transparent;
}

.cursor-pointer {
    cursor: pointer;
}

.hoverable:hover {
    opacity: 0.8;
}



.fc-day {
    border: none !IMPORTANT;
}


.recharts-tooltip-wrapper {
    filter: none !important;
    box-shadow: none !important;
}